import React from 'react';
import Logo from '../../assets/images/logo.png';
import LogoMini from '../../assets/images/logo-mini.png';
import styles from './Logo.module.css';

const logo = (props) => (
    <>
        <div className={[styles.logo]}>
            <a href='/'><img src={Logo} alt="LeadVenture Marketing" /></a>
        </div>
        <div className={[styles.mini]}>
            <a href='/'><img src={LogoMini} alt="LeadVenture Marketing" /></a>
        </div>
    </>
);

export default logo;