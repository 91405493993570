import React from "react";
import styles from "./NavigationItem.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

const NavigationItem = (props) => {
    if (props.link.startsWith("http")) {
        return (
            <li className={styles.item}>
                <a href={`${props.link}`} className={styles.href}>
                    <FontAwesomeIcon className={styles.icon} icon={`${props.icon}`}/>
                    <h4 className={styles.title}>{props.children}</h4>
                </a>
            </li>
        );
    } else {
        return (
            <li className={styles.item}>
                <Link to={`${props.link}`} className={styles.href}>
                    <FontAwesomeIcon className={styles.icon} icon={`${props.icon}`}/>
                    <h4 className={styles.title}>{props.children}</h4>
                </Link>
            </li>
        );
    }
};

export default NavigationItem;
