import React, { Component } from 'react';
import * as env from '../../store/actions/environment';

const withAWSAuth = (WrappedComponent) => {
  return class extends Component {
    state = {
      loggedIn: false,
    };

    componentDidMount() {
      if (this.getUserInfo() === true) {
        this.setState({ loggedIn: true });
        if (window.location.hash.length > 0) {
          window.location.replace('');
        }
      } else {
        window.location.replace(
          'https://sign-in.digmstools.com/login?client_id=63h4o70p1lkss04d8h004qmau7&response_type=token&scope=email+openid+profile&redirect_uri=' +
            env.APP_BASE_URL +
            '/'
        );
      }
    }

    getUserInfo = () => {
      try {
        let token = localStorage.getItem('token');
        let expires = localStorage.getItem('expiresAt');
        if (
          token === null ||
          expires === null ||
          token === undefined ||
          expires === undefined ||
          new Date(expires) < Date.now()
        ) {
          var hashURL = window.location.hash
            .substring(1)
            .split('&')
            .map((v) => v.split('='))
            .reduce((pre, [key, value]) => ({ ...pre, [key]: value }), {});
          if (hashURL.id_token !== null || hashURL.id_token !== undefined) {
            let daysTilPasswordExpires = parseInt(hashURL.expires_in);
            let milliSecToSecondRatio = 1000;
            let timeNow = new Date();
            let expiredTime = new Date(
              timeNow.getTime() + daysTilPasswordExpires * milliSecToSecondRatio
            );
            if (
              hashURL.id_token.length > 20 ||
              new Date(expires) > Date.now()
            ) {
              localStorage.setItem('token', hashURL.id_token);
              localStorage.setItem('expiresAt', expiredTime);
              return true;
            } else {
              return false;
            }
          } else {
            return false;
          }
        } else {
          return true;
        }
      } catch (err) {
        console.log('Error is: ' + err);
      }
    };

    render() {
      if (this.state.loggedIn) {
        return <WrappedComponent {...this.props} />;
      } else {
        return null;
      }
    }
  };
};

export default withAWSAuth;
