import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const intitalState = {
  error: false,
  remainingWorkingDays: -1,
};

const failedLoad = (state) => {
  return updateObject(state, {
    error: true,
  });
};

const getWorkingDays = (state) => {
  let moment = require('moment-business-days');
  let nyd = '01-01-2021';
  let today = new Date();
  moment.updateLocale('us', {
    workingWeekdays: [1, 2, 3, 4, 5],
    holidays: [nyd],
  });
  let daysLeft = moment(today, 'MM-DD-YYYY').businessDiff(
    moment('04-30-2021', 'MM-DD-YYYY')
  );
  return updateObject(state, {
    remainingWorkingDays: daysLeft,
  });
};

const reducer = (state = intitalState, action) => {
  switch (action.type) {
    case actionTypes.Failed_Load:
      return failedLoad(state, action);
    case actionTypes.GET_WORKING_DAYS:
      return getWorkingDays(state);
    default:
      return state;
  }
};

export default reducer;
