import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
  rows: [],
  filteredRows: [],
  repData: [],
  totalClients: 0,
  totalClientsForRep: 0,
  totalWorkDonePercentage: 0,
};

const setClientData = (state, action) => {
  // Handle DS Information First
  let dsClientData = action.result.dsData;
  let dsRowStartOfDSRepData = 99;
  let dsHeaderRowOfDSData = 1;
  let dsRepData = [];

  // Hard Coded Client Name Column Due to no Header in DS Google Sheet
  let dsGoogleSheetClientNameIndex = 1;
  let dsWorkDoneIndex = dsClientData[dsHeaderRowOfDSData].indexOf(
    'All Monthly Work Completed'
  );
  let dsRepNameIndex = dsClientData[dsHeaderRowOfDSData].indexOf('Rep');
  let dsPackageIndex = dsClientData[dsHeaderRowOfDSData].indexOf('Package');
  for (let row = dsRowStartOfDSRepData; row < dsClientData.length; row++) {
    if (
      dsClientData[row][dsRepNameIndex] !== undefined &&
      dsClientData[row][dsRepNameIndex] !== ''
    ) {
      {
        dsRepData.push({
          clientName: dsClientData[row][dsGoogleSheetClientNameIndex],
          repName: dsClientData[row][dsRepNameIndex],
          platform: 'Dealer Spike',
          workDone: dsClientData[row][dsWorkDoneIndex] !== '' ? 1 : 0,
          totalClientsForRep: dsClientData[row],
          package: dsClientData[row][dsPackageIndex],
        });
      }
    }
  }

  // Handle ARI Information Second
  let ariClientData = action.result.ariData;
  let ariTopRowForSecondHeader = 0;
  let ariHeaderRowOfDSData = 2;
  let ariRowStartOfARIRepData = 3;
  let ariRepdata = [];
  let ariWorkDoneIndex =
    ariClientData[ariHeaderRowOfDSData].indexOf(
      'All Monthly Work Completed'
    ) !== -1
      ? ariClientData[ariHeaderRowOfDSData].indexOf(
          'All Monthly Work Completed'
        )
      : ariClientData[ariTopRowForSecondHeader].indexOf(
          'All Monthly Work Completed'
        );
  let ariPackageIndex = ariClientData[ariHeaderRowOfDSData].indexOf('Package');
  let ariClientNameIndex = ariClientData[ariHeaderRowOfDSData].indexOf(
    'Client'
  );
  let workDoneBool;
  for (let row = ariRowStartOfARIRepData; row < ariClientData.length; row++) {
    if (
      ariClientData[row][ariClientNameIndex] !== undefined &&
      ariClientData[row][ariClientNameIndex] !== '' &&
      ariClientData[row][ariPackageIndex] !== undefined &&
      ariClientData[row][ariPackageIndex] !== 'Clients:' &&
      ariClientData[row][ariPackageIndex] !== 'Package'
    ) {
      if (
        ariClientData[row][ariWorkDoneIndex] !== undefined &&
        ariClientData[row][ariWorkDoneIndex] !== '' &&
        ariClientData[row][ariWorkDoneIndex] === '1'
      ) {
        workDoneBool = 1;
      } else {
        workDoneBool = 0;
      }
      ariRepdata.push({
        clientName: ariClientData[row][ariClientNameIndex],
        repName: ariClientData[row][ariClientData[row].length - 1],
        platform: 'ARI',
        workDone: workDoneBool,
        package: ariClientData[row][ariPackageIndex],
      });
    }
  }

  // Clean DS Data Before Merging Sets
  dsRepData.map((client) => {
    if (client.package.startsWith('P')) {
      return (client.package = 'Platinum');
    } else if (client.package.startsWith('G')) {
      return (client.package = 'Gold');
    } else {
      return (client.package = 'Silver');
    }
  });

  // Clean ARI data Before Merging
  ariRepdata.map((client) => {
    if (client.package.startsWith('Platinum')) {
      return (client.package = 'Platinum');
    } else if (client.package.startsWith('G')) {
      return (client.package = 'Gold');
    } else if (client.package.startsWith('Essentials')) {
      return (client.package = 'Essentials');
    } else if (client.package.startsWith('ESS PRO')) {
      return (client.package = 'ESS PRO');
    } else if (client.package.startsWith('Pro')) {
      return (client.package = 'Pro');
    } else if (client.package.startsWith('Premium')) {
      return (client.package = 'Premium');
    } else {
      return (client.package = 'Silver');
    }
  });

  // Merge the Data Sets
  Array.prototype.push.apply(dsRepData, ariRepdata);
  let finalRepData = dsRepData;

  // Add Individual Rep Data to State
  let clientData;
  let clientDataList = [];
  finalRepData.map((client) => {
    clientData = {
      repName: client.repName,
      clientName: client.clientName,
      platform: client.platform,
      package: client.package,
      status: client.workDone,
    };
    return clientDataList.push(clientData);
  });

  // Calculate other values before Formatting for Grid
  let totalSEOClients = finalRepData.length;
  let totalCompletedClients = 0;
  finalRepData.map((client) => {
    if (client.workDone === 1) {
      return totalCompletedClients++;
    } else {
      return totalCompletedClients;
    }
  });

  // Set Total Work Done Percentage
  let percentageWorkDone =
    (parseInt(totalCompletedClients) / parseInt(totalSEOClients)).toFixed(4) *
    100;

  // Format Data for React Grid
  let rowData = [];
  let allRepNames = finalRepData
    .map((client) => client.repName)
    .filter((value, index, self) => self.indexOf(value) === index);

  for (let rep = 0; rep < allRepNames.length; rep++) {
    let totalClientsForRep = 0,
      workDoneAmount = 0,
      silverPackageCount = 0,
      goldPackageCount = 0,
      platinumPackageCount = 0,
      essentialsPackageCount = 0,
      essentialsProPackageCount = 0,
      proPackageCount = 0,
      premiumPackageCount = 0;

    let repPlatform = '';

    for (let client = 0; client < finalRepData.length; client++) {
      if (finalRepData[client].repName === allRepNames[rep]) {
        totalClientsForRep++;
        repPlatform = finalRepData[client].platform;

        if (finalRepData[client].workDone === 1) {
          workDoneAmount++;
        }

        switch (finalRepData[client].package) {
          case 'Silver':
            silverPackageCount++;
            break;
          case 'Gold':
            goldPackageCount++;
            break;
          case 'Platinum':
            platinumPackageCount++;
            break;
          case 'Essentials':
            essentialsPackageCount++;
            break;
          case 'ESS PRO':
            essentialsProPackageCount++;
            break;
          default:
            break;
        }
      }
    }

    //  Set Individual Rep Work Done Percentage
    let repWorkCompleted =
      (parseInt(workDoneAmount) / parseInt(totalClientsForRep)).toFixed(4) *
      100;

    // Create Complete Dataset
    rowData.push({
      repName: allRepNames[rep],
      platform: repPlatform,
      workDone: repWorkCompleted,
      totalClientsForRep: totalClientsForRep,
      numOfSilverPackages: silverPackageCount,
      numOfGoldPackages: goldPackageCount,
      numOfPlatinumPackages: platinumPackageCount,
      numOfEssentialsPackages: essentialsPackageCount,
      numOfEssentialsProPackages: essentialsProPackageCount,
      numOfProPackages: proPackageCount,
      numOfPremiumPackages: premiumPackageCount,
    });
  }

  // Update the State with all Found Values
  let updatedState = updateObject(state, {
    rows: rowData,
    repData: clientDataList,
    filteredRows: rowData,
    totalClients: totalSEOClients,
    totalWorkDonePercentage: percentageWorkDone,
  });
  return updateObject(state, updatedState);
};

export const searchFilterControls = (e) => {
  let filteredRows = this.props.rows;
  filteredRows = filteredRows.filter((row) => {
    let repName = (row.name.firstName + ' ' + row.name.lastName).toLowerCase();
    return repName.indexOf(e.target.value) !== -1;
  }, this.state.setFilteredRows(filteredRows));
};

// Sort the Rows
const sortRows = (initialRows, sortColumn, sortDirection) => {
  let rows = initialRows;
  let comparer = (a, b) => {
    if (sortDirection === 'ASC') {
      return a[sortColumn] > b[sortColumn] ? 1 : -1;
    } else if (sortDirection === 'DESC') {
      return a[sortColumn] < b[sortColumn] ? 1 : -1;
    }
  };
  return sortDirection === 'NONE' ? initialRows : [...rows].sort(comparer);
};

// Filter the Rows
const filterRows = (state, action) => {
  let updatedState = updateObject(state, {
    filteredRows: action.updatedFilteredRows,
  });
  return updateObject(state, updatedState);
};

// Updates the Grid after Sorting / Filtering
const updateGrid = (state, action) => {
  let updatedRows = sortRows(
    state.rows,
    action.sortColumn,
    action.sortDirection
  );
  let updatedFilteredRows = sortRows(
    state.filteredRows,
    action.sortColumn,
    action.sortDirection
  );
  let updatedState = updateObject(state, {
    rows: updatedRows,
    filteredRows: updatedFilteredRows,
  });
  return updateObject(state, updatedState);
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_CLIENT_DATA:
      return setClientData(state, action);
    case actionTypes.UPDATE_GRID:
      return updateGrid(state, action);
    case actionTypes.FILTER_ROWS:
      return filterRows(state, action);
    default:
      return state;
  }
}

export default reducer;
