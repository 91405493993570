import React from "react";

import styles from './LeftSideNav.module.css';

import NavigationItems from '../../Navigation/NavigationItems/NavigationItems';

const LeftSideNav = () => (
  <nav>
    <div className={styles.sidenav}>
      <NavigationItems />
    </div>

    <div>
      <div className={styles.footer}>
        <p>{process.env.REACT_APP_NAME}</p>
        <p>Version: {process.env.REACT_APP_VERSION} © LeadVenture 2020 - {new Date().getFullYear()}</p>
      </div>
    </div>
  </nav>
);

export default LeftSideNav;
