import React from "react";

import styles from "./NavigationItems.module.css";

import NavigationItem from './NavigationItem/NavigationItem';

const NavigationItems = (props) => {
    return (
      <div className={styles.mainLeftBar}>
        <div className={styles.sidenav}>
          <h3>Pages</h3>
          <ul className={styles.list}>
            <NavigationItem icon='tachometer-alt' link='/'>
              SEO Dashboard
            </NavigationItem>
          </ul>
          <h3>Tools</h3>
          <ul className={styles.list}>
            <NavigationItem
              icon='camera-retro'
              link='https://www.seo-internal-tools.digmstools.com/screenshot-tool'
            >
              Screenshot Tool
            </NavigationItem>
            <NavigationItem
              icon='keyboard'
              link='https://www.seo-internal-tools.digmstools.com/seomonitor-keyword-export-tool'
            >
              Keyword Export Tool
            </NavigationItem>
            <NavigationItem
              icon='not-equal'
              link='https://www.seo-internal-tools.digmstools.com/title-description-tool'
            >
              SERP Checker
            </NavigationItem>
          </ul>
        </div>
      </div>
    );
};

export default NavigationItems;
