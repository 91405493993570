import React, { Component } from 'react';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import asyncComponent from '../../hoc/asyncComponent/asyncComponent';
import withAWSAuth from '../../hoc/withAWSAuth/withAWSAuth';
import Layout from '../../components/UI/Layout/Layout';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faCogs,
  faTachometerAlt,
  faCameraRetro,
  faKeyboard,
  faNotEqual,
} from '@fortawesome/free-solid-svg-icons';
library.add(faCogs, faTachometerAlt, faCameraRetro, faKeyboard, faNotEqual);

const asyncMainSEODashboard = asyncComponent(() => {
  return import('../SEODashboard/SEODashboard');
});

class App extends Component {
  render() {
    return (
      <div>
        <Layout>
          <Switch>
            <Route path='/' exact component={asyncMainSEODashboard} />
            <Redirect from='*' to='/' />
          </Switch>
        </Layout>
      </div>
    );
  }
}

export default withAWSAuth(withRouter(connect()(App)));
