import React, { Component } from "react";
import { connect } from "react-redux";

import styles from "./Layout.module.css";

import LeftSideNav from '../LeftSideNav/LeftSideNav';
import Toolbar from '../Toolbar/Toolbar';

class Layout extends Component {
    state = {};

    render() {
        return (
            <>
                <div className={[styles.toolbar]}>
                    <Toolbar/>
                </div>
                <div className={[styles.sidenav]}>
                    <LeftSideNav/>
                </div>
                <div className={[styles.content]}>
                    {this.props.children}
                </div>
            </>
        );
    }
}

export default connect()(Layout);
