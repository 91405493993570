// export const DS_BASE_URL = "https://localhost:44375/" // DEV Local
// export const DS_BASE_URL = "http://webapi.115.dev.dealerspike.net/" // DEV SERVER

export const DS_BASE_URL = 'https://webapi.corp.dealerspike.com/'; // SERVER

export const GOOGLE_SHEETS_GET_URL = 'api/v1/googlesheets/get/';

// SEO Dashboard parameters
export const DS_SEO_CLIENT_SHEET_ID =
  '12ull7Ldjq4PtAeuB4Ttk9EbBrHxmiZ7c0bW2cs7uZk4';
export const ARI_SEO_CLIENT_SHEET_ID =
  '1GjXBWsYP2yyC45rANKa-j1ECfIpWzDYJhepRz8mtTgA';

export const DS_SEO_CLIENT_SHEET_RANGE = 'April 2021!A:ZZ';
export const ARI_SEO_CLIENT_SHEET_RANGE = '!A:ZZ';
export const ARI_REP_NAMES = [
  'Danika',
  'Alex',
  'Jesika',
  'Josh R.',
  'Katie',
  'Kyle R.',
  'Nick H.',
  'Nick M.',
  'Kate',
  'Mia',
];

export const PROJECTED_HOURS = {
  silver: 1,
  gold: 2,
  platinumDS: 2.5,
  essentials: 2,
  pro: 2,
  essPro: 2.5,
  premium: 3,
  platinumARI: 3.5,
};

// Authentication
// export const APP_BASE_URL = "https://seo-dashboard.digmstools.com" // PROD
export const APP_BASE_URL = "https://stage.seo-dashboard.digmstools.com" // STAGE
// export const APP_BASE_URL = "http://localhost:3000" // DEV