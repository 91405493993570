import React from "react";
import { Navbar, Form, FormControl, InputGroup } from 'react-bootstrap';
import { connect } from "react-redux";

import styles from "./Toolbar.module.css";

import Logo from '../../Logo/Logo';

const Toolbar = () => (
    <>
        <Navbar bg="light" expand="lg" style={{height:'64px'}}>
            <Navbar.Brand className={[styles.logo]}>
                <Logo/>
            </Navbar.Brand>
            <Form inline className={[styles.search]}>
                <InputGroup className={[styles.searchinput]}>
                    <FormControl
                        placeholder="Search"
                        aria-label="Search"
                    />
                </InputGroup>
            </Form>
        </Navbar>
    </>
);

export default connect()(Toolbar);
